<template>
    <div class="pagebox">
        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 专家服务 -->
        <div class="expertbox">
            <div class="expertcon">
                <div class="expertlist">
                    <div class="ebox">
                        <div class="box">
                            <div class="img" :style="{background:'url('+expertdata.HeadImageFileUrl+') no-repeat center center / cover'}"></div>
                            <div class="txt">
                                <div class="name">{{ expertdata.TeacherName }}</div>
                                <div class="tips">
                                    <span v-for="(it,ind) in expertdata.TeacherTagList" :key="ind">{{ it.TagName }}</span>
                                </div>
                            </div>
                            <div class="yuyue" @click.prevent.stop="toreservation()">立即预约</div>
                            <div class="clear"></div>
                            <div class="mark">{{ expertdata.Summary }}</div>
                        </div>
                    </div>
                    <div class="expertmark">
                        <div class="titleico">
                            <div class="ico"><i></i><span>专家介绍</span><div class="clear"></div></div>
                            <div class="clear"></div>
                        </div>
                        <div class="expertcontent" v-html="expertdata.RichTextContent"></div>
                    </div>
                </div>
                <div class="otherexpert">
                    <div class="titleico">
                        <div class="ico"><i></i><span>专家团队</span><div class="clear"></div></div>
                        <div class="clear"></div>
                    </div>
                    <div class="oitem" v-for="(item,index) in expertdataother" :key="index" @click.prevent="$router.push('/expertdetail?id='+item.Id)">
                        <div class="img" :style="{background:'url('+item.HeadImageFileUrl+') no-repeat center center / cover'}"></div>
                        <div class="txt">
                            <div class="name">{{item.TeacherName}}</div>
                            <div class="tips">
                                <span v-for="(it,ind) in item.TeacherTagList" :key="ind">{{ it.TagName }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clear"></div>
            </div>
        </div>

        <!-- 专家预约 -->
        <Reservation :reservationid="$route.query.id" ref="expertreservation"></Reservation>

        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import Reservation from "../components/reservation/reservation.vue";
import API from "@/api/config";
export default{
    components:{
        PHeader,
        PFooter,
        Reservation
    },
    data(){
        return{
            expertdata:{},
            expertdataother:[]
        }
    },
    watch: {
        $route: function(to, from) {
            this.GetTeacherDetail();
        }
    },
    mounted(){
        this.GetTeacherDetail();
        this.GetTeacherList();
    },
    methods:{
        //获取专家
        GetTeacherDetail(){
            API.Web.GetTeacherDetail({Id:this.$route.query.id}).then(res => {
                if(res.Code==0){
                    this.expertdata = res.Data;
                }
            });
        },
        //获取专家
        GetTeacherList(){
            API.Web.GetTeacherList({pageIndex:1,pageSize:10}).then(res => {
                if(res.Data.Data.length>0){
                    this.expertdataother = res.Data.Data;
                }
            });
        },
        //预约
        toreservation(){
            this.$refs.expertreservation.reservationshow=true;
            this.$refs.expertreservation.layerclass='layershow';
            this.$refs.expertreservation.layerboxclass="layerboxshow";
        },
    }
}
</script>
<style lang="less" scoped>
.expertbox{
    max-width: 1420px;
    margin: 20px auto 50px;
    user-select: none;
    .expertcon{
        margin: 0px 20px;
        border: 1px solid #eee;
        box-shadow: 0px 0px 3px 1px #ebeaea;
        .expertlist{
            width: calc(100% - 400px);
            margin: 10px 10px;
            float: left;
            .ebox{
                width: 100%;
                .box{
                    width: calc(100% - 20px);
                    margin: 10px;
                    background-color: #fff;
                    box-shadow: 0px 0px 3px 1px #ebeaea;
                    border-radius: 5px;
                    .img{
                        width: 100px;
                        height: 100px;
                        float: left;
                        margin: 10px 15px;
                        overflow: hidden;
                        border-radius: 100px;
                    }
                    .txt{
                        float: left;
                        width: calc(100% - 340px);
                        margin-left: 10px;
                        .name{
                            color: #555;
                            font-size: 20px;
                            font-weight: bold;
                            margin-top: 18px;
                        }
                        .tips{
                            width: 100%;
                            margin-top: 15px;
                            span{
                                font-size: 12px;
                                color: #6a6969;
                                background-color: #f9ede0;
                                margin-right: 10px;
                                padding: 2px 15px;
                                border-radius: 20px;
                                margin-bottom: 5px;
                                display: inline-block;
                                user-select: none;
                            }
                        }
                    }
                    .yuyue{
                        float: right;
                        margin-right: 30px;
                        margin-top: 40px;
                        width: 150px;
                        height: 40px;
                        background-color: rgba(var(--themecolor),0.7);
                        color: #fff;
                        font-size: 16px;
                        line-height: 40px;
                        text-align: center;
                        transition: all 0.2s;
                        -webkit-transition: all 0.2s;
                    }
                    .yuyue:hover{
                        cursor: pointer;
                        background-color: rgba(var(--themecolor),0.85);
                    }
                    .mark{
                        margin: 0px 20px;
                        padding-bottom: 20px;
                        padding-top: 10px;
                        text-indent: 26px;
                        color: #666;
                        font-size: 13px;
                        line-height: 26px;
                        border-top: 1px solid #e2e2e2;
                    }
                }
            }
            .expertmark{
                background-color: #fff;
                margin: 10px;
                border: 1px solid #eee;
                box-shadow: 0px 0px 3px 1px #ebeaea;
                .titleico{
                    height: 50px;
                    line-height: 48px;
                    background-color: #fff;
                    overflow: hidden;
                    border-bottom: 1px solid #ebebeb;
                    .ico{
                        float: left;
                        i{
                            float: left;
                            margin: 15px 15px 0px 20px;
                            width: 6px;
                            height: 19px;
                            background-color: rgb(var(--themecolor));
                        }
                        span{
                            font-size: 16px;
                            color: #555;
                            font-weight: bold;
                        }
                    }
                }
                .expertcontent{
                    margin: 30px 40px;
                    overflow: hidden;
                    color: #555;
                    font-size: 14px;
                    line-height: 24px;
                    text-indent: 28px;
                    padding-bottom: 30px;
                    img{
                        display: block;
                        max-width: 100%;
                        margin: auto;
                    }
                }
            }
        }
        .otherexpert{
            width: 358px;
            float: right;
            margin-right: 20px;
            margin-top: 20px;
            border: 1px solid #eee;
            box-shadow: 0px 0px 3px 1px #ebeaea;
            .titleico{
                height: 50px;
                line-height: 48px;
                background-color: #fff;
                overflow: hidden;
                border-bottom: 1px solid #ebebeb;
                .ico{
                    float: left;
                    i{
                        float: left;
                        margin: 15px 15px 0px 20px;
                        width: 6px;
                        height: 19px;
                        background-color: rgb(var(--themecolor));
                    }
                    span{
                        font-size: 16px;
                        color: #555;
                        font-weight: bold;
                    }
                }
            }
            .oitem:hover{
                cursor: pointer;
                box-shadow: 0px 0px 3px 1px rgba(var(--themecolor),0.3);
            }
            .oitem{
                margin: 10px;
                height: 82px;
                background-color: #fff;
                border-radius: 5px;
                transition: all 0.2s;
                -webkit-transition: all 0.2s;
                .img{
                    margin: 10px;
                    width: 60px;
                    height: 60px;
                    float: left;
                    overflow: hidden;
                    border-radius: 60px;
                }
                .txt{
                    float: right;
                    margin-right: 10px;
                    width: calc(100% - 90px);
                    .name{
                        color: #555;
                        font-size: 16px;
                        font-weight: bold;
                        margin-top: 10px;
                    }
                    .tips{
                        margin-top: 5px;
                        height: 42px;
                        overflow: hidden;
                        span{
                            color: #777;
                            font-size: 12px;
                            border-radius: 10px;
                            padding: 1px 5px;
                            margin: 1px 0px;
                            margin-right: 10px;
                            display: inline-block;
                            background-color: #f8f6f6;
                        }
                    }
                }
            }
        }
    }
}
</style>